import { Search as ElementsSearch, useGetNodes, useGetWorkspace } from '@stoplight/elements-dev-portal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';

const Search = ({ projectIds }) => {
  const [search, setSearch] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const { data, isFetching } = useGetNodes({
    search,
    projectIds,
  });
  const { data: workspace } = useGetWorkspace({
    projectIds,
  });

  const handleClose = () => {
    setOpen(false);
    setSearch('');
  };

  const handleClick = (data) => {
    if (data.project_id === process.env.GATSBY_STOPLIGHT_API_REFERENCE_PROJECT_ID) {
      // Ideally use gatsby navigate, but something is bjorked. So do a janky window.location call.
      // navigate(`/api-reference/${data.slug}`, {state: {}, replace: false});
      window.location.href = `/api-reference/${data.slug}`;
    } else if (data.project_id === process.env.GATSBY_STOPLIGHT_DOCS_PROJECT_ID) {
      window.location.href = `/docs/${data.slug}`;
    }
  };

  return (
    <>
      <span onClick={() => setOpen(true)} >
        Search {' '}
        <FontAwesomeIcon icon={faSearch} />
      </span>
      <ElementsSearch
        search={search}
        onSearch={setSearch}
        onClick={handleClick}
        onClose={handleClose}
        isOpen={open}
        isLoading={isFetching}
        searchResults={data}
      />
    </>
  );
};

export default Search;
