/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';

import '../styles/global.css';


/** @typedef {import("react").ReactNode } ReactNode */

/**
 * @arg {{children: ReactNode}} _
 */
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
    <Helmet>
      <script>
        { // OneTrust Cookies Consent Notice for pagerduty.com
          `
(function () {
  function initGoogleAnalytics(key) {
    // console.log('ga');
    var gtm = document.createElement('script');
    gtm.src = 'https://www.googletagmanager.com/gtag/js?id=' + key;
    document.head.appendChild(gtm);
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', key);
    window.gtag = gtag;
  }

  /*
   * For the definition of each of these categories see:
   * https://pagerduty.atlassian.net/wiki/spaces/GROW/pages/1440088433/
   */
  var necessary = 'C0001';
  var performance = 'C0002';
  var functional = 'C0003';
  var targeting = 'C0004';
  var social = 'C0005';

  window.OptanonWrapper = function() {
    // console.log('consent set/changed');
    if (window.OnetrustActiveGroups != null) {
      const userGdprPrefs = OnetrustActiveGroups.split(/s*,s*/).filter(it => it.length > 0);
      if (0 <= userGdprPrefs.indexOf(necessary)) {
        // run these strictly necessary scripts
      }
      if (0 <= userGdprPrefs.indexOf(performance)) {
        // run these performance scripts
      }
      if (0 <= userGdprPrefs.indexOf(functional)) {
        // run these functional scripts
      }
      if (0 <= userGdprPrefs.indexOf(targeting)) {
        // run these targeting scripts
        initGoogleAnalytics('UA-8759953-1');
      }
      if (0 <= userGdprPrefs.indexOf(social)) {
        // run these social scripts
      }
    }
  }
}())
         `}
      </script>
      <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
              data-domain-script="a81929ae-2ada-45d6-b686-853c02c2b6c2"></script>
    </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
    </>
  );
};

export default Layout;
